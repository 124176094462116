/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CHECKOUT_ADDRESS_POPUP_ID } from 'Component/CheckoutAddressPopup/CheckoutAddressPopup.config';
import history from 'SourceUtil/History';
import { updateShippingFields } from 'Store/Checkout/Checkout.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { CustomerType } from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { noopFn } from 'Util/Common';

import CheckoutAddressBook from './CheckoutAddressBook.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CheckoutDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Checkout/Checkout.dispatcher'
);
/** @namespace Tigerone/Component/CheckoutAddressBook/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    shipping_method_code: state.CheckoutReducer.shipping_method_code,
    shippingFields: state.CheckoutReducer.shippingFields,
    all_shipping_methods: state.CheckoutReducer.all_shipping_methods,
    updatedAddress: state.CheckoutReducer.address
});

/** @namespace Tigerone/Component/CheckoutAddressBook/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateShippingFields: (fields) => dispatch(updateShippingFields(fields)),
    showCheckoutAddressPopup: (payload) => dispatch(showPopup(CHECKOUT_ADDRESS_POPUP_ID, payload)),
    // eslint-disable-next-line max-len
    handleUpdateAddress: (address) => CheckoutDispatcher.then(({ default: d }) => d.handleUpdateAddress(address, dispatch))

});

/** @namespace Tigerone/Component/CheckoutAddressBook/Container */
export class CheckoutAddressBookContainer extends PureComponent {
    static propTypes = {
        requestCustomerData: PropTypes.func.isRequired,
        onShippingEstimationFieldsChange: PropTypes.func,
        onAddressSelect: PropTypes.func,
        customer: CustomerType.isRequired,
        isBilling: PropTypes.bool,
        isSubmitted: PropTypes.bool,
        handleUpdateAddress: PropTypes.func.isRequired,
        clickProceedToBillingButton: PropTypes.func.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        is_virtual: PropTypes.bool
    };

    static defaultProps = {
        isBilling: false,
        onAddressSelect: noopFn,
        onShippingEstimationFieldsChange: noopFn,
        isSubmitted: false,
        is_virtual: false
    };

    static _getDefaultAddressId(props) {
        const { customer, isBilling } = props;
        const defaultKey = isBilling ? 'default_billing' : 'default_shipping';
        const { [defaultKey]: defaultAddressId, addresses } = customer;

        if (parseInt(defaultAddressId, 10)) {
            return +defaultAddressId;
        }

        if (addresses && addresses.length) {
            return addresses[0].id;
        }

        return 0;
    }

    containerFunctions = ({
        onAddressSelect: this.onAddressSelect.bind(this),
        openAddressPopup: this.openAddressPopup.bind(this)
    });

    static getDerivedStateFromProps(props, state) {
        const { prevDefaultAddressId } = state;
        const defaultAddressId = CheckoutAddressBookContainer._getDefaultAddressId(props);

        if (defaultAddressId !== prevDefaultAddressId) {
            return {
                selectedAddressId: defaultAddressId,
                prevDefaultAddressId: defaultAddressId
            };
        }

        return null;
    }

    componentDidMount() {
        const { selectedAddressId } = this.state;
        const { onAddressSelect } = this.props;
        const { location: { pathname } } = history;
        const fromNegotiableQuotes = pathname.includes('negiotablequotecheckout');

        if (selectedAddressId) {
            if (fromNegotiableQuotes) {
                this.setState({
                    selectedAddressId: BrowserDatabase.getItem('shipping_address_id')
                });
            }
            onAddressSelect(selectedAddressId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            onAddressSelect,
            requestCustomerData,
            customer,
            shipHereClickToggle,
            handleUpdateAddress,
            updatedAddress: { isLastUpdated, address_id }
        } = this.props;
        const { selectedAddressId: prevSelectedAddressId } = prevState;
        const { shipHereClickToggle: prevshipHereClickToggle } = prevProps;
        const { selectedAddressId } = this.state;

        if (isSignedIn() && !Object.keys(customer).length) {
            requestCustomerData();
        }

        if (shipHereClickToggle !== prevshipHereClickToggle) {
            requestCustomerData();
        }

        if (selectedAddressId !== prevSelectedAddressId) {
            if (selectedAddressId === 0) {
                return;
            }
            onAddressSelect(selectedAddressId);
            this.estimateShipping(selectedAddressId);
        }

        if (isLastUpdated === true) {
            const { addresses } = customer;
            const defaultAddress = this._getDefaultAddressId(this.props);
            if (defaultAddress) {
                addresses.every((address) => {
                    if (address.id === address_id) {
                        this.onAddressSelect(address);
                        handleUpdateAddress({ address_id: 0, isLastUpdated: false });
                        return false;
                    }

                    return true;
                });
            } else {
                handleUpdateAddress({ address_id: 0, isLastUpdated: false });
            }
        }
    }

    _getDefaultAddressId(props) {
        const { customer, isBilling } = props;
        const defaultKey = isBilling ? 'default_billing' : 'default_shipping';
        const { [defaultKey]: defaultAddressId, addresses } = customer;

        if (parseInt(defaultAddressId, 10)) {
            return +defaultAddressId;
        }

        if (addresses && addresses.length) {
            return addresses[0].id;
        }

        return 0;
    }

    __construct(props) {
        super.__construct(props);

        const {
            requestCustomerData,
            customer: { id, addresses },
            onAddressSelect
        } = props;

        if (isSignedIn() && !id) {
            requestCustomerData();
        }

        const defaultAddressId = CheckoutAddressBookContainer._getDefaultAddressId(props);

        if (defaultAddressId) {
            onAddressSelect(defaultAddressId);
            this.estimateShipping(defaultAddressId);
            addresses.every((address) => {
                if (address.id === defaultAddressId) {
                    this.onAddressSelect(address);
                    return false;
                }

                return true;
            });
        }
        this.state = {
            prevDefaultAddressId: defaultAddressId,
            selectedAddressId: defaultAddressId
        };
    }

    containerProps() {
        const {
            customer,
            onShippingEstimationFieldsChange,
            isBilling,
            isSubmitted,
            is_virtual,
            clickProceedToBillingButton,
            setNewFormAddress,
            saveAddress,
            showError
        } = this.props;
        const { selectedAddressId, prevDefaultAddressId } = this.state;

        return {
            customer,
            onShippingEstimationFieldsChange,
            isBilling,
            selectedAddressId,
            prevDefaultAddressId,
            isSubmitted,
            is_virtual,
            clickProceedToBillingButton,
            setNewFormAddress,
            saveAddress,
            showError
        };
    }

    onAddressSelect(address) {
        const { updateShippingFields, shippingFields: { id: addressId = 0 } } = this.props;

        if (Object.keys(address).length) {
            updateShippingFields(address);
        }
        const { id = addressId } = address;
        this.setState({ selectedAddressId: id });
    }

    async estimateShipping(addressId) {
        const {
            onShippingEstimationFieldsChange,
            // clickProceedToBillingButton,
            customer: { addresses = [] }
        } = this.props;

        const address = addresses.find(({ id }) => id === addressId);

        if (!address) {
            return;
        }

        const {
            city,
            country_id,
            postcode,
            region: {
                region_id,
                region
            } = {}
        } = address;

        if (!country_id) {
            return;
        }

        onShippingEstimationFieldsChange({
            city,
            country_id,
            region_id,
            region,
            postcode
        });

        // clickProceedToBillingButton();
    }

    openAddressPopup() {
        const { showCheckoutAddressPopup, onShippingEstimationFieldsChange, isSubmitted } = this.props;

        const payload = {
            onShippingEstimationFieldsChange: { onShippingEstimationFieldsChange },
            id: 'SHIPPING_STEP',
            isSubmitted
        };

        showCheckoutAddressPopup({
            title: 'Add New Address',
            payload
        });
    }

    render() {
        return (
            <CheckoutAddressBook
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressBookContainer);
