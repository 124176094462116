/* eslint-disable no-undef */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
 * Returns fields for street
 * @param props
 * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}]|*[]}
 * @namespace Tigerone/Component/MyAccountAddressForm/Form/getStreetFields */
export const getStreetFields = (props) => {
    const {
        addressLinesQty = 1,
        address: { street = [] }
    } = props;

    if (addressLinesQty === 1) {
        return [{
            type: FIELD_TYPE.text,
            label: 'Street address',
            attr: {
                name: 'street',
                defaultValue: street[0] || '',
                placeholder: 'Your street address'
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            }
        }];
    }

    const streets = [];

    // eslint-disable-next-line fp/no-loops, fp/no-let
    for (let i = 0; i < addressLinesQty; i++) {
        streets.push({
            type: FIELD_TYPE.text,
            label: 'Street Address',
            attr: {
                name: `street_${i}`,
                defaultValue: street[i] || ''
            },
            addRequiredTag: i === 0,
            validateOn: i === 0 ? ['onChange'] : [],
            validationRule: {
                isRequired: i === 0
            }
        });
    }

    return streets;
};

/**
 * Returns region fields
 * @param props
 * @param events
 * @returns {[{addRequiredTag: boolean, validateOn: (string[]|*[]), validationRule: {isRequired}, options, label: *, type: string, attr: {defaultValue: number, name: string, selectPlaceholder: *}}]|*[]|[{validateOn: (string[]|*[]), validationRule: {isRequired}, label: *, type: string, attr: {defaultValue, name: string, id: string, placeholder: *}}]}
 * @namespace Tigerone/Component/MyAccountAddressForm/Form/getRegionFields  */
export const getRegionFields = (props, events) => {
    const {
        currentRegion,
        currentRegionId,
        regionDisplayAll,
        availableRegions,
        isStateRequired
    } = props;

    const { onRegionChange, onRegionIdChange } = events;

    if (!regionDisplayAll && !isStateRequired) {
        return [];
    }

    if (!availableRegions || !availableRegions.length) {
        return [
            {
                type: FIELD_TYPE.text,
                label: 'State / Province',
                attr: {
                    id: 'address-region-id',
                    name: 'region_string',
                    value: currentRegion,
                    placeholder: 'Your state / province'
                },
                events: {
                    onChange: onRegionChange
                },
                addRequiredTag: isStateRequired,
                validateOn: isStateRequired ? ['onChange'] : [],
                validationRule: {
                    isRequired: isStateRequired
                }
            }
        ];
    }

    return [
        {
            type: FIELD_TYPE.select,
            label: 'State / Province',
            attr: {
                name: 'region_id',
                value: currentRegionId,
                selectPlaceholder: 'Select region...'
            },
            events: {
                onChange: onRegionIdChange
            },
            options: availableRegions.map(({ id, name }) => ({
                id,
                label: name,
                value: id
            })),
            addRequiredTag: isStateRequired,
            validateOn: isStateRequired ? ['onChange'] : [],
            validationRule: {
                isRequired: isStateRequired
            }
        }
    ];
};

/**
 * Returns VAT fields
 * @param props
 * @returns {*[]|[{label: *, type: string, attr: {defaultValue: string, name: string}}]}
 * @namespace Tigerone/Component/MyAccountAddressForm/Form/getVatFields */
export const getVatFields = (props) => {
    const { showVatNumber, vat_id: vatID = '' } = props;

    if (!showVatNumber) {
        return [];
    }

    return [
        {
            type: FIELD_TYPE.text,
            label: 'VAT Number',
            attr: {
                placeholder: 'Your VAT number',
                name: 'vat_id',
                defaultValue: vatID
            },
            addRequiredTag: false,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: false
            }
        }
    ];
};

/**
 * Returns address form fields
 * @param props
 * @param events
 * @returns {[{label: *, type: string, attr: {defaultChecked, name: string}}, {label: *, type: string, attr: {defaultChecked, name: string}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {mods: {street: boolean, multipleFields: boolean, oddAddresses: boolean}, name: string, fields: ({addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}[]|*[])}, null, ...*[]|{label: *, type: string, attr: {defaultValue: string, name: string}}[], null]}
 * @namespace Tigerone/Component/MyAccountAddressForm/Form/myAccountAddressForm */
export const myAccountAddressForm = (props, events = {}) => {
    const {
        // default_billing: defaultBilling,
        default_shipping: defaultShipping,
        firstname = '',
        lastname = '',
        city = '',
        company = '',
        countries = [],
        postcode: zipcode = '',
        telephone = '',
        addressLinesQty = 1,
        countryId
    } = props;

    const CountryValidator = countries[0].id === 'US' || countryId === 'US';

    const { onCountryChange, onZipcodeChange, onCityChange } = events || {};

    return [
        {
            name: 'NameGroup',
            mods: { address: true },
            fields: [
                {
                    type: FIELD_TYPE.text,
                    label: 'First name',
                    attr: {
                        name: 'firstname',
                        defaultValue: firstname
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true,
                        inputType: VALIDATION_INPUT_TYPE.alphaSpace
                    }
                },
                {
                    type: FIELD_TYPE.text,
                    label: 'Last name',
                    attr: {
                        name: 'lastname',
                        defaultValue: lastname
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true,
                        inputType: VALIDATION_INPUT_TYPE.alphaSpace
                    }
                }
            ]
        },
        {
            name: 'addressGroup CompanyName',
            mods: { address: true },
            fields: [
                ...getVatFields(props),
                {
                    name: 'company',
                    mods: { CompanyName: true },
                    fields: [
                        {
                            type: FIELD_TYPE.text,
                            label: 'Company name',
                            attr: {
                                name: 'company',
                                defaultValue: company
                            }
                        }
                    ]
                },
                // ...getPhoneNumberField(props)
                {
                    type: FIELD_TYPE.text,
                    label: 'Phone number',
                    attr: {
                        name: 'telephone',
                        defaultValue: telephone,
                        maxLength: 14
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        inputType: VALIDATION_INPUT_TYPE.phone,
                        isRequired: true
                    }
                }
            ]
        },
        {
            name: 'streetGroup',
            mods: {
                street: true,
                multipleFields: addressLinesQty > 0,
                oddAddresses: addressLinesQty % 2 === 1
            },
            fields: getStreetFields(props)
        },
        {
            name: 'addressGroup',
            mods: { Region: true },
            fields: [
                {
                    type: FIELD_TYPE.text,
                    label: 'City',
                    attr: {
                        name: 'city',
                        defaultValue: city
                    },
                    events: {
                        onChange: onCityChange
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true
                    }
                },
                {
                    type: FIELD_TYPE.text,
                    label: 'Postcode/ZIP',
                    attr: {
                        name: 'postcode',
                        defaultValue: zipcode
                    },
                    events: {
                        onChange: onZipcodeChange
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange', 'onBlur'],
                    validationRule: {
                        ...(CountryValidator && { inputType: VALIDATION_INPUT_TYPE.zipcode }),
                        isRequired: true
                    }
                },
                ...getRegionFields(props, events),
                {
                    type: FIELD_TYPE.select,
                    label: 'Country',
                    attr: {
                        id: 'address-country-id',
                        name: 'country_id',
                        value: countryId,
                        selectPlaceholder: 'Select country...'
                    },
                    events: {
                        onChange: onCountryChange
                    },
                    options: countries,
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true
                    }
                }
            ]
        },
        // {
        //     type: FIELD_TYPE.checkbox,
        //     label: 'Use as my default billing address',
        //     attr: {
        //         name: 'default_billing',
        //         defaultChecked: defaultBilling
        //     }
        // },
        {
            type: FIELD_TYPE.checkbox,
            label: 'Use as my default shipping address',
            attr: {
                name: 'default_shipping',
                defaultChecked: defaultShipping
            }
        }
    ];
};

export default myAccountAddressForm;
