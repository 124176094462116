/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps, ProductWishlistButtonContainer as SourceProductWishlistButtonContainer } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { isSignedIn } from 'SourceUtil/Auth';

/** @namespace Tigerone/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    containerProps() {
        const {
            magentoProduct, mix, handleRemoveItem, isEditing
        } = this.props;

        return {
            mix,
            magentoProduct,
            isDisabled: this.isDisabled(),
            isInWishlist: this.isInWishlist(),
            isSignedIn: isSignedIn(),
            handleRemoveItem,
            isEditing
        };
    }

    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            showNotification,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
            handleRemoveItem,
            isEditing
        } = this.props;

        const {
            isWishListToggle
        } = this.state;

        if (!isSignedIn()) {
            return showNotification('info', 'You must login or register to add items to your wishlist.');
        }

        this.setWishlistButtonLoading(true);

        if (isWishListToggle) {
            return;
        }

        this.setState({ isWishListToggle: true });

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId
            }).then(
                /** @namespace Tigerone/Component/ProductWishlistButton/Container/ProductWishlistButtonContainer/toggleProductInWishlist/addProductToWishlist/then */
                () => {
                    // eslint-disable-next-line no-unused-expressions
                    isEditing ? handleRemoveItem() : null;
                }
            );

            this.setState({ isWishListToggle: false });

            return;
        }

        const wishlistItem = this.getWishlistItem(sku);

        if (!wishlistItem) {
            this.setState({ isWishListToggle: false });

            return;
        }

        const {
            wishlist: {
                id: itemId
            }
        } = wishlistItem;

        this.setState({ isWishListToggle: false });

        return removeProductFromWishlist({ item_id: itemId });
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
