/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import { FieldFile as SourceFieldFile } from 'SourceComponent/FieldFile/FieldFile.component';
import { EventsType, FieldAttrType } from 'Type/Field.type';

/**
 * Field File
 * @class FieldFile
 * @namespace Tigerone/Component/FieldFile/Component */
export class FieldFileComponent extends SourceFieldFile {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        fileName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    renderSubLabel(allowedTypes) {
        return (
            <p block="FieldFile" elem="AllowedTypes">
                Compatible file extensions to upload:
                <strong>{ ` ${allowedTypes}` }</strong>
            </p>
        );
    }

    renderFileLabel() {
        const {
            attr: { id = '', multiple = false } = {},
            fileName,
            isLoading = false
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (fileName) {
            return (
                <label htmlFor={ id } className={ fileName ? 'success' : null }>
                    <p>
                        { fileName }
                    </p>
                    { fileName ? (
                        <div className="successTick">
                            <svg
                              fill="#40C057"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 30 30"
                              width="20px"
                              height="20px"
                            >
                                <path d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 21.627 8.373 27 15 27 C 21.627 27 27 21.627 27 15 C 27 12.820623 26.409997 10.783138 25.394531 9.0214844 L 14.146484 20.267578 C 13.959484 20.454578 13.705453 20.560547 13.439453 20.560547 C 13.174453 20.560547 12.919422 20.455578 12.732422 20.267578 L 8.2792969 15.814453 C 7.8882969 15.423453 7.8882969 14.791391 8.2792969 14.400391 C 8.6702969 14.009391 9.3023594 14.009391 9.6933594 14.400391 L 13.439453 18.146484 L 24.240234 7.3457031 C 22.039234 4.6907031 18.718 3 15 3 z M 24.240234 7.3457031 C 24.671884 7.8662808 25.053743 8.4300516 25.394531 9.0195312 L 27.707031 6.7070312 C 28.098031 6.3150312 28.098031 5.6839688 27.707031 5.2929688 C 27.316031 4.9019687 26.683969 4.9019688 26.292969 5.2929688 L 24.240234 7.3457031 z" />
                            </svg>
                        </div>
                    ) : null }
                </label>
            );
        }

        const selectLabel = multiple ? 'Choose files' : 'Choose file';

        return (
            <label htmlFor={ id }>
                <p>{ id === 'csvFile' ? <>Upload CSV file</> : <>.PNG/ .JPEG/ .PDF/ .JPG/</> }</p>
                <span>{ selectLabel }</span>
            </label>
        );
    }

    render() {
        const {
            attr = {},
            attr: { accept = '' } = {},
            events = {},
            fileEvents = {},
            setRef
        } = this.props;

        const allowedFieldTypes = (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .join(', ');

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  type={ FIELD_TYPE.file }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...fileEvents }
                />
                { this.renderFileLabel() }
                { allowedFieldTypes.length > 0 && this.renderSubLabel(allowedFieldTypes) }
            </>
        );
    }
}

export default FieldFileComponent;
