/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import FieldFile from 'Component/FieldFile';
import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import { FIELD_TYPE } from './Field.config';

import './Field.override.style';

/** @namespace Tigerone/Component/Field/Component */
export class FieldComponent extends SourceField {
    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            style,
            bablicExclude
        } = this.props;

        if (style) {
            return (
                <FieldSelectContainer
                  attr={ attr }
                  events={ events }
                  options={ options }
                  setRef={ setRef }
                  isDisabled={ isDisabled }
                  isSortSelect={ isSortSelect }
                  changeValueOnDoubleClick={ changeValueOnDoubleClick }
                  style
                />
            );
        }

        return (
            <FieldSelectContainer
              attr={ attr }
              events={ events }
              bablicExclude={ bablicExclude }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              isSortSelect={ isSortSelect }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
            />
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;

        const { id = '', checked, value = '' } = newAttr;
        const { _owner: { key = '' } = {} } = label;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ `${id}${key}` } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  { ...newAttr }
                  { ...inputEvents }
                  // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ isChecked }
                />
                <div block="input-control" disabled={ isDisabled } />
                { label }
            </label>
        );
    }

    renderFile() {
        const {
            attr, events, setRef, fileEvents
        } = this.props;

        return (
            <FieldFile attr={ attr } events={ events } setRef={ setRef } fileEvents={ fileEvents } />
        );
    }

    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="Field" elem="SubLabelContainer">
                <div block="Field" elem="SubLabel">
                    { subLabel }
                </div>
            </div>
        );
    }

    renderLabel() {
        const { type, label, attr: { name } = {} } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={ name || `input-${type}` }>
                    { label }
                    { this.renderRequiredTag() }
                </label>
            </div>
        );
    }

    render() {
        const {
            type, validationResponse, mix
        } = this.props;
        const inputRenderer = this.renderMap[type];
        const { mods: { hasError = false } = {} } = mix;

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: !hasError && validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}
export default FieldComponent;
