/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getFormattedRegion } from 'Util/Address';

/** @namespace Tigerone/Component/MyAccountAddressTable/Table/getAddressAdditionalTableFields */
export const getAddressAdditionalTableFields = (address, countries, allCountries) => {
    const regionData = getFormattedRegion(address, countries, allCountries);

    return [
        {
            key: 'city',
            label: 'City',
            source: address
        },
        {
            key: 'region',
            label: 'State/Province',
            source: regionData
        },
        {
            key: 'country',
            label: 'County',
            source: regionData
        },
        {
            key: 'vat_id',
            label: 'VAT Number',
            source: address
        }
    ];
};

/** @namespace Tigerone/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const {
        address, countries, allCountries
    } = props;

    return [
        {
            key: 'firstname',
            label: 'First name',
            source: address
        },
        {
            key: 'lastname',
            label: 'Last name',
            source: address
        },
        {
            key: 'street',
            label: 'Street',
            source: address
        },
        {
            key: 'postcode',
            label: 'Postal code',
            source: address
        },
        {
            key: 'telephone',
            label: 'Phone number',
            source: address
        },
        ...(getAddressAdditionalTableFields(address, countries, allCountries))
    ];
};
