/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Form from 'Component/Form';
import { CheckoutAddressForm as SourceCheckoutAddressForm } from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.component';
import {
    checkoutSaveAddresstrimCustomerAddress,
    trimSaveAddressCheckoutAddress
} from 'Util/Address';
import transformToNameValuePair from 'Util/Form/Transform';

import checkoutAddressForm from './CheckoutAddressForm.form';

/** @namespace Tigerone/Component/CheckoutAddressForm/Component */
export class CheckoutAddressFormComponent extends SourceCheckoutAddressForm {
    componentDidMount() {
        // const {
        //     address: {
        //         countryId,
        //         regionId,
        //         region,
        //         city,
        //         postcode
        //     },
        //     defaultCountry,
        //     onShippingEstimationFieldsChange
        // } = this.props;

        // onShippingEstimationFieldsChange({
        //     country_id: countryId || defaultCountry,
        //     region_id: regionId !== '' ? regionId : null,
        //     region,
        //     city,
        //     postcode
        // });
    }

    // #region GETTERS
    get fieldMap() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange
        } = this.props;

        return checkoutAddressForm({
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            ...address
        }, {
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange
        });
    }

    renderSection(section) {
        const {
            fields,
            attr: {
                name = ''
            } = {},
            name: sectionName
        } = section;

        // If contains attr fields then outputs data as fields
        if (Array.isArray(fields)) {
            return (
               // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
               <FieldGroup { ...section } key={ name || sectionName }>
                   { fields.map(this.renderSection.bind(this)) }
               </FieldGroup>
            );
        }

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        return <Field { ...section } key={ name } />;
    }

    onAddressChange(event, data) {
        const { fields = {} } = data;
        const {
            country_id,
            region_id: regionId,
            region_string: region,
            city,
            postcode
        } = transformToNameValuePair(fields);

        // const { onShippingEstimationFieldsChange } = this.props;
        const request = {
            country_id,
            region_id: regionId !== '' ? regionId : null,
            region,
            city,
            postcode
        };

        // If request hasn't changed, then ignore.
        if (JSON.stringify(request) === JSON.stringify(this.lastRequest)) {
            return;
        }

        // onShippingEstimationFieldsChange(request);

        // Caches last request
        this.lastRequest = request;
    }

    getFormProps() {
        return {
            onSubmit: this.onSubmit.bind(this)
        };
    }

    async onSubmit(form, fields) {
        const {
            country_id,
            region_id: regionId,
            region_string: region,
            city,
            postcode
        } = transformToNameValuePair(fields);

        const {
            onShippingEstimationFieldsChange, hideActiveOverlay, setNewFormAddress, saveAddress
        } = this.props;

        const newAddress = trimSaveAddressCheckoutAddress(transformToNameValuePair(fields));

        const shippingAddress = checkoutSaveAddresstrimCustomerAddress(transformToNameValuePair(fields));

        // save address
        if (transformToNameValuePair(fields).save_in_address_book) {
            await saveAddress(shippingAddress);
        }

        setNewFormAddress(newAddress);

        const request = {
            country_id,
            region_id: regionId !== '' ? regionId : null,
            region,
            city,
            postcode
        };

        onShippingEstimationFieldsChange(request);
        hideActiveOverlay();
    }

    renderActions() {
        return (
            <div block="CheckoutAddressForm" elem="ShipButton">
                <button
                  type={ FIELD_TYPE.submit }
                  block="Button"
                  mix={ { block: 'CheckoutAddressForm', elem: 'Button' } }
                  mods={ { isHollow: true } }
                >
                    Ship Here
                </button>
            </div>
        );
    }

    render() {
        return (
            <div block="CheckoutAddressForm">
                <Form { ...this.getFormProps() } block="FieldForm">
                    { this.renderFormBody() }
                </Form>
            </div>
        );
    }
}
export default CheckoutAddressFormComponent;
