/* eslint-disable no-unexpected-multiline */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import {
    PRINT_ALL_INVOICES,
    PRINT_ALL_REFUNDS,
    PRINT_ALL_SHIPMENT,
    PRINT_INVOICE,
    PRINT_ORDER as PRINT_ORDER_REQUEST,
    PRINT_REFUND,
    PRINT_SHIPMENT
} from 'Component/MyAccountOrderPrint/MyAccountOrderPrint.config';
import UrlRewrites from 'Route/UrlRewrites';
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';
import {
    ADDRESS_BOOK, COMPANY_CREDIT, COMPANY_PROFILE, COMPANY_STRUCTURE, COMPANY_USER, INSTOCK_SUBSCRIPTION,
    MY_DOWNLOADABLE, MY_ORDERS, MY_QUOTES, MY_WISHLIST, NEWSLETTER_SUBSCRIPTION, QUICK_ORDER,
    STORE_CREDIT
} from 'Type/Account.type';

import {
    ACCOUNT_FORGOT_PASSWORD,
    Add_SCRIPT_LINKS,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CHECKOUT_FAILURE,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    CREATE_ACCOUNT,
    DEMO_NOTICE,
    DOWNLOADS,
    HEADER,
    HOME,
    LOGIN,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_COMPANY_CREDIT,
    MY_ACCOUNT_COMPANY_PROFILE,
    MY_ACCOUNT_COMPANY_STRUCTURE,
    MY_ACCOUNT_COMPANY_USER,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_INSTOCK_SUBSCRIPTION,
    MY_ACCOUNT_MY_QUOTE,
    MY_ACCOUNT_MY_QUOTES,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_QUICK_ORDER,
    MY_ACCOUNT_STORE_CREDIT,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEGOTIABLEQUOTECHECKOUT,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    PRINT_ORDER,
    SEARCH,
    SELECT_COUNTRY_POPUP,
    SHARED_WISHLIST,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    TEFPAY_NOTIFY,
    URL_REWRITES
} from './Router.config';

import './Router.style';

export const AddScriptLinks = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "post-affiliate" */ 'Component/AddScriptLinks'));
export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const Downloads = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "downloads" */ 'Component/Downloads'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const CookiePopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/CookiePopup'));
export const DemoNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/DemoNotice'));
export const Header = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ 'Route/SearchPage'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/ConfirmAccountPage'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/MenuPage'));
export const Footer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'));
export const NavigationTabs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/NavigationTabs'));
export const NewVersionPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup'));
export const NotificationList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'));
export const OfflineNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/OfflineNotice'));
export const ContactPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/ContactPage'));
export const ProductComparePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ProductComparePage'));
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ForgotPassword'));
export const SomethingWentWrong = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "something-went-wrong" */ 'Route/SomethingWentWrong'));
export const StyleGuidePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/StyleGuidePage'));
export const Breadcrumbs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Breadcrumbs'));
export const OrderPrintPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "print-order" */ 'Route/OrderPrintPage'));
export const SelectCountryPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/SelectCountryPopup'));
export const MyviewQuote = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/MyviewQuote'));
export const TefPayNotify = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/TefPayNotify'));
export const CheckoutSuccess = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkoutSuccess" */ 'Route/CheckoutSuccess'));
export const CheckoutFailure = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkoutFailure" */ 'Route/CheckoutFailure'));

/** @namespace Tigerone/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => {
    const { storeList } = window;
    // eslint-disable-next-line prefer-const, fp/no-let
    let newStoreRegax = [];
    storeList.map((store) => {
        newStoreRegax.push(`${store}-en`);
        newStoreRegax.push(`${store}-fr`);
        newStoreRegax.push(`${store}-it`);
        newStoreRegax.push(`${store}-es`);
        newStoreRegax.push(`${store}-de`);
        newStoreRegax.push(`${store}-nl`);
        return null;
    });
    window.newStoreList = newStoreRegax;
    newStoreRegax = newStoreRegax.join('|');
    newStoreRegax = `/(${ newStoreRegax })?`;
    window.newStoreRegax = newStoreRegax;
    return newStoreRegax.concat(path);
};
/** @namespace Tigerone/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NAVIGATION_TABS
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        },
        {
            component: <SelectCountryPopup />,
            position: 36,
            name: SELECT_COUNTRY_POPUP
        },
        {
            component: <AddScriptLinks />,
            position: 37,
            name: Add_SCRIPT_LINKS
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50,
            name: CART
        },
        {
            component: <Route path={ withStoreRegex('/negiotablequotecheckout/:uid?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 48,
            name: NEGOTIABLEQUOTECHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/checkout/failure') } render={ (props) => <CheckoutFailure { ...props } /> } />,
            position: 53,
            name: CHECKOUT_FAILURE
        },
        {
            component: <Route path={ withStoreRegex('/checkout/success') } render={ (props) => <CheckoutSuccess { ...props } /> } />,
            position: 54,
            name: CHECKOUT_SUCCESS
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 70,
            name: MY_ACCOUNT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 71,
            name: MY_ACCOUNT_ORDERS
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_WISHLIST } /> } />,
            position: 73,
            name: MY_ACCOUNT_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ (props) => <MyAccount { ...props } selectedTab={ ADDRESS_BOOK } /> } />,
            position: 74,
            name: MY_ACCOUNT_ADDRESS
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ (props) => <MyAccount { ...props } selectedTab={ NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 76,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80,
            name: MENU
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81,
            name: SHARED_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82,
            name: CONTACT_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83,
            name: COMPARE
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 84,
            name: STYLE_GUIDE
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ORDER_REQUEST } /> } />,
            position: 90,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_INVOICE } /> } />,
            position: 94,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_REFUND } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/quick-order') } render={ (props) => <MyAccount { ...props } selectedTab={ QUICK_ORDER } /> } />,
            position: 96,
            name: MY_ACCOUNT_QUICK_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/store-credit') } render={ (props) => <MyAccount { ...props } selectedTab={ STORE_CREDIT } /> } />,
            position: 97,
            name: MY_ACCOUNT_STORE_CREDIT
        },
        {
            component: <Route path={ withStoreRegex('/negotiable_quote/quote/view/quote_id/:id?') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_QUOTES } /> } />,
            position: 101,
            name: MY_ACCOUNT_MY_QUOTE
        },
        {
            component: <Route path={ withStoreRegex('/negotiable_quote/quote') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_QUOTES } /> } />,
            position: 102,
            name: MY_ACCOUNT_MY_QUOTES
        },
        {
            component: <Route path={ withStoreRegex('/company-structure') } render={ (props) => <MyAccount { ...props } selectedTab={ COMPANY_STRUCTURE } /> } />,
            position: 103,
            name: MY_ACCOUNT_COMPANY_STRUCTURE
        },
        {
            component: <Route path={ withStoreRegex('/sales/instock-subscription') } render={ (props) => <MyAccount { ...props } selectedTab={ INSTOCK_SUBSCRIPTION } /> } />,
            position: 104,
            name: MY_ACCOUNT_INSTOCK_SUBSCRIPTION
        },
        {
            component: <Route path={ withStoreRegex('/company-credit') } render={ (props) => <MyAccount { ...props } selectedTab={ COMPANY_CREDIT } /> } />,
            position: 98,
            name: MY_ACCOUNT_COMPANY_CREDIT
        },
        {
            component: <Route path={ withStoreRegex('/company-profile') } render={ (props) => <MyAccount { ...props } selectedTab={ COMPANY_PROFILE } /> } />,
            position: 99,
            name: MY_ACCOUNT_COMPANY_PROFILE
        },
        {
            component: <Route path={ withStoreRegex('/company-user') } render={ (props) => <MyAccount { ...props } selectedTab={ COMPANY_USER } /> } />,
            position: 105,
            name: MY_ACCOUNT_COMPANY_USER
        },
        {
            component: <Route path={ withStoreRegex('/resources') } render={ (props) => <Downloads { ...props } /> } />,
            position: 106,
            name: DOWNLOADS
        },
        {
            component: <Route path={ withStoreRegex('/tefpay/notify') } render={ (props) => <TefPayNotify { ...props } /> } />,
            position: 107,
            name: TEFPAY_NOTIFY
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        }
    ];
}

export default RouterComponent;
