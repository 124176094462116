/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductComponent } from 'Component/Product/Product.component';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    GRID_LAYOUT,
    LIST_LAYOUT
} from 'Route/CategoryPage/CategoryPage.config';
import { ACCOUNT_LOGIN_URL } from 'SourceRoute/MyAccount/MyAccount.config';
import { isSignedIn } from 'SourceUtil/Auth';
import { MixType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { LayoutType } from 'Type/Layout.type';
import { LinkType } from 'Type/Router.type';
import { productFeaturesIcon, productShareIcon } from 'Util/Images';
import { formatCurrency } from 'Util/Price';

import ProductLabel from '../../@scandiweb/mageplaza-product-label/component/ProductLabel';

import './ProductCard.override.style';

/**
 * Product card
 * @class ProductCard
 * @namespace Tigerone/Component/ProductCard/Component */
export class ProductCardComponent extends ProductComponent {
    static propTypes = {
        ...ProductComponent.propTypes,
        linkTo: LinkType,
        device: DeviceType.isRequired,
        thumbnail: PropTypes.string,
        isLoading: PropTypes.bool,
        children: PropTypes.element,
        layout: LayoutType,
        mix: MixType,
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        hideWishlistButton: PropTypes.bool,
        isWishlistEnabled: PropTypes.bool.isRequired,
        hideCompareButton: PropTypes.bool,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        showSelectOptionsNotification: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        closeSearchOverlay: PropTypes.func,
        shareIconClicked: PropTypes.func,
        notifyClick: PropTypes.func,
        categoryUrlSuffix: PropTypes.string
    };

    static defaultProps = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: GRID_LAYOUT
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this)
        }
    };

    imageRef = createRef();

    className = 'ProductCard';

    registerSharedElement = this.registerSharedElement.bind(this);

    registerSharedElement() {
        const { registerSharedElement } = this.props;
        document.documentElement.scrollIntoView();
        registerSharedElement(this.imageRef);
    }

    //#region PRICE
    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderPrice() {
        const {
            getActiveProduct,
            product: { type_id: baseType } = {},
            isSearchPageCard
        } = this.props;

        const { price_range: priceRange, type_id: typeId } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (isSearchPageCard) {
            const {
                minimum_price: { final_price: { value, currency } = {} }
            } = priceRange;

            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div
                      className="ProductPrice"
                      block="ProductPrice"
                      elem="Price search-page"
                      mods={ { isPreview: true } }
                    >
                        { !value ? null : (
                            <span block="ProductPrice" elem="Price">
                                <span block="ProductPrice" elem="PriceBadge">
                                    From
                                </span>
                                <span block="ProductPrice" elem="PriceValue">
                                    { formatCurrency(currency) }
                                    { ' ' }
                                    { value }
                                </span>
                            </span>
                        ) }
                    </div>
                </div>
            );
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        return super.renderPrice(notConfigured);
    }
    //#endregion

    renderPicture(mix = {}) {
        const {
            product: {
                id, name, image: { url } = {}, type_id
            },
            thumbnail,
            imageShow,
            activeProduct: { image: { path } = {} }
        } = this.props;

        this.sharedConfigComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ !path ? url : thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail || url }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { (type_id === PRODUCT_TYPE.configurable)
                    ? this.sharedConfigComponent
                    : this.sharedComponent }
                <img
                  style={ imageShow ? null : { display: 'none' } }
                  alt={ name }
                  src={ thumbnail || url }
                />
            </>
        );
    }

    renderReviews() {
        const { layout } = this.props;

        return (
            <div block="ProductCard" elem="Reviews" mods={ { layout } }>
                { this.renderRatingSummary() }
            </div>
        );
    }

    renderProductCompareButton() {
        const { hideCompareButton } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return this.renderCompareButton();
    }

    renderProductCardWishlistButton() {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderProductShareButton() {
        const { shareIconClicked, product: { url } = {} } = this.props;
        return (
            <button
              block="ProductButton"
              elem="ShareButton"
              type="button"
              onClick={ () => shareIconClicked(url) }
            >
                { productShareIcon() }
            </button>
        );
    }

    renderProductActions() {
        const { layout } = this.props;

        if (layout === 'grid') {
            return (
                <div block="ProductCard" elem="ProductActions">
                    { this.renderProductCardWishlistButton() }
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { this.renderProductShareButton() }
                { this.renderProductCompareButton() }
            </div>
        );
    }

    renderMainDetails() {
        const { linkTo } = this.props;
        return (
            <>
                <div block="ProductCard" elem="ContentGrid">
                    { this.renderBrand() }
                    { this.renderStatus() }
                </div>
                <Link to={ linkTo }>{ this.renderName(false) }</Link>
                { this.renderSkuName() }
            </>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo,
            product: { url, url_key },
            isSearchPageCard,
            closeSearchOverlay,
            productName,
            categoryUrlSuffix
        } = this.props;

        const shouldIncludeOnClick = isSearchPageCard;

        if (isSearchPageCard) {
            return (
                <Link
                  block="ProductCard"
                  elem="Link"
                  to={ categoryUrlSuffix
                      ? `${url_key}${categoryUrlSuffix}`
                      : url_key }
                  onClick={ closeSearchOverlay }
                  mix={ mix }
                  title={ productName }
                >
                    { children }
                </Link>
            );
        }

        if (!url) {
            return (
                <div block="ProductCard" elem="Link">
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ shouldIncludeOnClick ? this.registerSharedElement : null }
              mix={ mix }
              title={ productName }
            >
                { children }
            </Link>
        );
    }

    requiresConfiguration() {
        const {
            product: {
                type_id: type,
                options = [],
                links_purchased_separately
            }
        } = this.props;

        const configureBundleAndGrouped = type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.grouped;
        const configureConfig = (type === PRODUCT_TYPE.configurable
                // eslint-disable-next-line max-len
                && Object.keys(super.getConfigurableAttributes()).length
                    !== Object.keys(this.getConfigurableAttributes()).length)
            // eslint-disable-next-line max-len
            || (type === PRODUCT_TYPE.configurable
                && Object.values(this.getConfigurableAttributes()).some(
                    (value) => value.attribute_values.length === 0
                ));
        const configureCustomize = options.some(
            ({ required = false }) => required
        );
        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return (
            configureBundleAndGrouped
            || configureConfig
            || configureCustomize
            || configureDownloadableLinks
        );
    }

    renderAddToCart() {
        const { layout } = this.props;

        // Deleted Default configuration method to add dropdown values

        return this.renderAddToCartButton(layout);
    }

    getConfigurableAttributes() {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(
            Object.entries(filteredOptions).filter(([, option]) => {
                const { attribute_options: attributeOptions = {} } = option;

                return Object.values(attributeOptions).some(
                    ({ swatch_data: swatchData }) => swatchData
                );
            })
        );
    }

    renderQuantity() {
        return this.renderQuantityChanger();
    }

    renderLoginLink() {
        const {
            handleLogin,
            product: { sku }
        } = this.props;

        if (!sku) {
            return <TextPlaceholder />;
        }

        return (
            <Link
              block="ProductCard"
              elem="LoginLink"
              to={ ACCOUNT_LOGIN_URL }
              onClick={ handleLogin }
            >
                Login to view price
            </Link>
        );
    }

    renderHoverLoginLink() {
        const {
            product: { sku },
            handleLogin
        } = this.props;

        if (!sku) {
            return <TextPlaceholder />;
        }

        return (
            <Link
              block="ProductCard"
              elem="HoverLoginLink"
              to={ ACCOUNT_LOGIN_URL }
              onClick={ handleLogin }
            >
                Login to view price
            </Link>
        );
    }

    renderVisibleOnHover() {
        const {
            device, product: { sku, type_id } = {}, inStock, layout
        } = this.props;

        if (device.isMobile || !sku) {
            return null;
        }

        return (
            <div block="ProductCard" elem="FooterWrapper">
                { this.renderProductFeatures(layout) }
                { this.renderConfigurableOptions() }
                { isSignedIn() ? (
                    <div>
                    { type_id === 'bundle' ? (
                        <div block="ProductCard" elem="Content">
                            { this.renderViewProductOnHover() }
                        </div>
                    ) : !inStock ? (
                        <div block="ProductCard" elem="Footer">
                            { this.renderNotifyStockButton() }
                        </div>
                    ) : (
                        <div block="ProductCard" elem="Footer">
                            { this.renderQuantity() }
                            { this.renderAddToCart() }
                        </div>
                    ) }
                    </div>
                ) : (
                    this.renderHoverLoginLink()
                ) }
            </div>
        );
    }

    renderProductFeatures(layout) {
        const { product: { attributes } = {} } = this.props;

        const filteredArray = [];
        let FeaturesList = [];

        if (layout === 'grid') {
            FeaturesList = ['seeds_feminised', 'seeds_flowering_weeks'];
        } else {
            FeaturesList = [
                'seeds_feminised',
                'seeds_flowering_type',
                'seeds_flowering_weeks',
                'seeds_taste'
            ];
        }

        if (attributes) {
            FeaturesList.map((attribute) => filteredArray.push(attributes[attribute]));
        }

        const value = filteredArray.map((attribute) => {
            if (attribute) {
                const { attribute_type } = attribute;

                if (attribute_type === 'select') {
                    const { attribute_options, attribute_value } = attribute;

                    return (
                        <li className="featuresAttributeOptions">
                            <span>{ productFeaturesIcon() }</span>
                            { attribute_options[attribute_value].label }
                        </li>
                    );
                }

                if (attribute_type === 'text') {
                    const { attribute_value } = attribute;
                    if (!attribute_value) {
                        return null;
                    }

                    return (
                        <li>
                            <span>{ productFeaturesIcon() }</span>
                            { attribute_value }
                        </li>
                    );
                }
            }

            return true;
        });

        return (
            <ul block="ProductCard" elem="ProductFeaturesLists">
                { value }
            </ul>
        );
    }

    renderViewButton() {
        const {
            layout,
            product: { sku }
        } = this.props;

        if (!sku) {
            return <TextPlaceholder length="medium" />;
        }

        return (
            <button block="Button ViewProduct" mods={ { layout } }>
                <span>View Product</span>
            </button>
        );
    }

    renderNotifyStockButton() {
        const { layout, notifyClick, product } = this.props;
        return (
            <button
              block="Button NotifyStock"
              mods={ { layout } }
              onClick={ () => notifyClick(product) }
            >
                <span>Notify Me Once Available</span>
            </button>
        );
    }

    renderProductActionsButtons() {
        const { inStock } = this.props;

        if (!inStock) {
            return <>{ this.renderNotifyStockButton() }</>;
        }

        return (
            <>
                <div block="ProductCard" elem="QuantityChanger">
                    <label>Select Qty:</label>
                    { this.renderQuantityChanger() }
                </div>
                { this.renderAddToCart() }
            </>
        );
    }

    getBrandContent() {
        const { layout } = this.props;

        if (this.renderBrandLogo()) {
            return (
                <div block="ProductCard" elem="BrandLogo" mods={ { layout } }>
                    { this.renderBrandLogo() }
                </div>
            );
        }

        return null;
    }

    renderCardContent() {
        const {
            renderContent,
            device: { isMobile },
            isSearchPageCard,
            product: { attributes, variants } = {}
        } = this.props;

        let isFeaturesList;

        const FeaturesList = ['seeds_feminised', 'seeds_flowering_weeks'];

        if (attributes) {
            isFeaturesList = FeaturesList.find(
                (attribute) => attribute === attributes[attribute]?.attribute_code
            );
        }

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                { this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="LinkInnerWrapper">
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                            { this.renderLabels() }
                            { this.getBrandContent() }
                        </div>
                        { this.renderProductActions() }
                        <div block="ProductCard" elem="Content">
                            <div block="ProductCard" elem="ContentGrid">
                                { this.renderBrand() }
                                { this.renderStatus() }
                            </div>
                            { this.renderName(false) }
                            { this.renderSkuName() }
                            { isSignedIn()
                                ? this.renderPrice()
                                : this.renderLoginLink() }
                            { isMobile || isSearchPageCard
                                ? this.renderViewButton()
                                : null }
                        </div>
                    </div>
                ) }
                { !isSearchPageCard ? (
                    <div
                      block="ProductCard"
                      elem={ isFeaturesList && variants?.length
                          ? 'VisibleOnHover'
                          : variants?.length
                              ? 'VisibleOnHover_withVariants'
                              : !isFeaturesList
                                  ? 'VisibleOnHover_noFeatures'
                                  : 'VisibleOnHover_withVariants' }
                    >
                        { this.renderVisibleOnHover() }
                    </div>
                ) : (
                    <div
                      block="ProductCard"
                      elem={ isFeaturesList && variants?.length
                          ? 'VisibleOnHover'
                          : variants?.length
                              ? 'VisibleOnHover_withVariants'
                              : !isFeaturesList
                                  ? 'VisibleOnHover_noFeatures'
                                  : 'VisibleOnHover_withVariants' }
                    >
                        { this.renderVisibleOnHover() }
                    </div>
                ) }
            </>
        );
    }

    renderLabel = (labelData) => {
        const {
            ruleId,
            productId,
            positionStyle,
            textStyle,
            customCss,
            imageSrc,
            label,
            tooltipLabel,
            fontUrl
        } = labelData;

        return (
          <ProductLabel
            key={ `${productId}_${ruleId}` }
            ruleId={ ruleId }
            productId={ productId }
            positionStyle={ positionStyle }
            textStyle={ textStyle }
            customCss={ customCss }
            imageSrc={ imageSrc }
            label={ label }
            fontUrl={ fontUrl }
            tooltipLabel={ tooltipLabel }
          />
        );
    };

    renderLabels() {
        const { labels } = this.props;
        return labels.map(this.renderLabel);
    }

    renderCardListContent() {
        const { layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                <div block="ProductCard" elem="LinkBlock-1">
                    <div block="ProductCard" elem="Link">
                        <div
                          block="ProductCard"
                          elem="Content"
                          mods={ { layout } }
                        >
                            <div block="ProductCard" elem="ContentMainBlock">
                                <div block="ProductCard" elem="MainInfo">
                                    { this.renderMainDetails() }
                                    { this.renderLabelsList() }
                                </div>
                                { isSignedIn() ? (
                                    <div
                                      block="ProductCard"
                                      elem="AttributeWrapper"
                                    >
                                        { this.renderPrice() }
                                    </div>
                                ) : (
                                    this.renderLoginLink()
                                ) }
                            </div>
                        </div>
                    </div>
                    <div block="ProductCard" elem="ProductFeatures">
                        { this.renderProductFeatures(layout) }
                    </div>
                    <div block="ProductCard" elem="ProductsActionIcons">
                        { this.renderProductActions() }
                    </div>
                </div>
                <div block="ProductCard" elem="LinkBlock-2">
                    <div block="ProductCard" elem="ConfigurableOptions">
                        { this.renderConfigurableOptions() }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        { isSignedIn()
                            ? this.renderProductActionsButtons()
                            : null }
                    </div>
                </div>
            </>
        );
    }

    renderLabelList = (labelData) => {
        const {
            ruleId,
            productId,
            positionStyle,
            textStyle,
            customCss,
            imageSrc,
            label,
            tooltipLabel,
            fontUrl
        } = labelData;

        return (
          <ProductLabel
            key={ `${productId}_${ruleId}` }
            ruleId={ ruleId }
            productId={ productId }
            positionStyle={ positionStyle }
            textStyle={ textStyle }
            customCss={ customCss }
            imageSrc={ imageSrc }
            label={ label }
            fontUrl={ fontUrl }
            tooltipLabel={ tooltipLabel }
          />
        );
    };

    renderLabelsList() {
        const { labels } = this.props;
        return labels.map(this.renderLabel);
    }

    renderViewProductOnHover() {
        const {
            product: { type_id, url_key, url }, layout
        } = this.props;

        if (type_id === PRODUCT_TYPE.bundle) {
            return (
                        <Link
                          to={ url_key || url }
                          block="Button ViewProduct"
                          mods={ { layout } }
                        >
                            <span>View Product</span>
                        </Link>
            );
        }
    }

    render() {
        const {
            children, mix, isLoading, layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li block="ProductCard" mods={ { layout } } mix={ mix }>
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={ { layout } } mix={ mix }>
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
