import WishlistQuery from 'Query/Wishlist.query';
import {
    isWishlistEnabled as sourceIsWishlistEnabled,
    WishlistDispatcher as ParentWishlistDispatcher
} from 'SourceStore/Wishlist/Wishlist.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { clearWishlist, updateAllProductsInWishlist, updateIsLoading } from 'Store/Wishlist/Wishlist.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import { fetchMutation, fetchQuery } from 'Util/Request';
import { getPriceRange } from 'Util/Wishlist';

// TODO: implement isWishlistEnabled
export const isWishlistEnabled = sourceIsWishlistEnabled;

/**
 * Product Wishlist Dispatcher
 * @class WishlistDispatcher
 * @namespace Tigerone/Store/Wishlist/Dispatcher */
export class WishlistDispatcher extends ParentWishlistDispatcher {
    updateInitialWishlistData(dispatch, page) {
        if (isSignedIn() && isWishlistEnabled()) {
            this._syncWishlistWithBE(dispatch, page);
        } else {
            dispatch(updateAllProductsInWishlist({}));
        }
    }

    _syncWishlistWithBE(dispatch, page) {
        // Need to get current wishlist from BE, update wishlist
        return fetchQuery(WishlistQuery.getWishlistQuery(__, page)).then(
            /** @namespace Tigerone/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then */
            (data) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                if (data && data.wishlist) {
                    const { wishlist, wishlist: { page_info, items_count } } = data;
                    const productsToAdd = wishlist.items.reduce((prev, wishlistItem) => {
                        const {
                            id,
                            sku,
                            product,
                            description,
                            price,
                            price_without_tax,
                            buy_request,
                            options,
                            qty: quantity
                        } = wishlistItem;

                        const priceRange = getPriceRange(product, price, price_without_tax);

                        return {
                            ...prev,
                            [id]: {
                                ...product,
                                ...priceRange,
                                quantity,
                                wishlist: {
                                    id,
                                    sku,
                                    quantity,
                                    description,
                                    buy_request,
                                    options
                                }
                            }
                        };
                    }, {});

                    dispatch(updateAllProductsInWishlist({ productsToAdd, page_info, items_count }));
                } else {
                    dispatch(updateIsLoading(false));
                }
            },
            /** @namespace Tigerone/Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
            () => {
                dispatch(updateIsLoading(false));
            }
        );
    }

    clearWishlist(dispatch) {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        return fetchMutation(WishlistQuery.getClearWishlist())
            .then(
                /** @namespace Tigerone/Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/fetchMutation/then */
                () => {
                    dispatch(updateIsLoading(false));
                    return dispatch(clearWishlist());
                }
            )
            .catch(
                /** @namespace Tigerone/Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch */
                () => {
                    dispatch(updateIsLoading(false));
                    return dispatch(showNotification('error', __('Error clearing wish list!')));
                }
            );
    }

    async addItemToWishlist(dispatch, options) {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;
            dispatch(updateIsLoading(true));
            await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, items));
            await this._syncWishlistWithBE(dispatch);
            dispatch(showNotification('success', 'Product added to wish-list!'));
        } catch {
            dispatch(showNotification('error', 'Error updating wish list!'));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }
}

export default new WishlistDispatcher();
