import {
    isSignedIn
} from 'Util/Auth';
import { getCountryAndLanguageCode } from 'Util/Url';

export const INTERVAL = 3000;

/** @namespace Tigerone/Util/Insider/Index/fireInsiderEvent */
export const fireInsiderEvent = (eventType, customerData = {}, isPersistent = true) => {
    if (!customerData) {
        window.insider_object = {};
        return;
    }

    const {
        id, email, is_subscribed, firstname, lastname
    } = customerData;

    const { countryCode, languageCode } = getCountryAndLanguageCode();

    const eventData = {
        ...(isSignedIn() ? { uuid: `${id}` } : null),
        gdpr_optin: true,
        name: firstname,
        surname: lastname,
        email,
        email_optin: is_subscribed,
        language: `${languageCode}-${countryCode !== 'uk' ? countryCode : 'gb'}`,
        custom: {
            outdoor_grow_guide: '',
            new_release_sign_up_email: '',
            new_release_sign_up: '',
            user_verified: isSignedIn()
        }
    };

    if (window.Insider && typeof window.Insider === 'object') {
        window.insider_object = { [eventType]: eventData };
    } else if (isPersistent) {
        setTimeout(() => {
            fireInsiderEvent(eventType, customerData, false);
        }, INTERVAL); // try again in 3 seconds
    }
};

/** @namespace Tigerone/Util/Insider/Index/fireInsiderPageEvent */
export const fireInsiderPageEvent = (pageType) => {
    if (window.Insider && typeof window.Insider === 'object') {
        window.insider_object = {
            page: {
                type: pageType
            }
        };
    }
};
