/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { CheckoutPayment as SourceCheckoutPayment } from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.override.style';

/** @namespace Tigerone/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceCheckoutPayment {
    // eslint-disable-next-line no-unused-vars
    renderPaymentField(options) {
        return null;
    }

    render() {
        const {
            isSelected,
            method: { title, code, payment_instructions },
            method,
            isValidCardDetails,
            isMobile
        } = this.props;

        const options = {
            method,
            isSelected,
            isValidCardDetails
        };

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <div block="CheckoutPayment" elem="CheckoutPaymentParent">
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g id="Group_1175" data-name="Group 1175" transform="translate(5696.313 20973.313)">
                        <rect id="Rectangle_1575" data-name="Rectangle 1575" width="30" height="30" transform="translate(-5696.313 -20973.313)" fill="#fff" opacity="0" />
                        <g id="noun-credit-cards-3707615" transform="translate(-5769.232 -20976.24)"><path id="Path_741" data-name="Path 741" d="M96.742,28.915H79.1a2.17,2.17,0,0,1-2.166-2.166V16.768A2.171,2.171,0,0,1,79.1,14.6h2.889v-5.5A2.17,2.17,0,0,1,84.153,6.94h9.981A2.17,2.17,0,0,1,96.3,9.106v5.5h.439a2.171,2.171,0,0,1,2.166,2.167v9.981a2.17,2.17,0,0,1-2.166,2.166ZM83.1,14.6h2.048V8.05h-.992A1.061,1.061,0,0,0,83.1,9.106v5.5Zm4.746,0h2.57a1.72,1.72,0,0,1,.343-1.939,1.72,1.72,0,1,1,2.434,0,1.72,1.72,0,0,1,.343,1.939h1.659v-5.5A1.06,1.06,0,0,0,94.136,8.05H87.844V14.6Zm.092,8.041a.555.555,0,0,1,0-1.111h5.9a.555.555,0,0,1,0,1.111Zm0,4.236a.555.555,0,0,1,0-1.11h5.9a.555.555,0,0,1,0,1.11Zm0-2.118a.555.555,0,0,1,0-1.11h8.192a.555.555,0,0,1,0,1.11ZM81.44,22.867a1.715,1.715,0,0,1,1.215.5,1.719,1.719,0,1,1,0,2.434,1.72,1.72,0,1,1-1.215-2.937ZM97.8,20.457H78.042v6.292A1.06,1.06,0,0,0,79.1,27.8H96.742A1.061,1.061,0,0,0,97.8,26.748Zm-19.755-2.7H97.8v-.992a1.061,1.061,0,0,0-1.056-1.056H79.1a1.061,1.061,0,0,0-1.056,1.056Z" /></g>
                        </g>
                    </svg>
                    </div>
                    <div>
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${title}`,
                          name: 'option-paymentMethod',
                          checked: !!isSelected,
                          defaultValue: code
                      } }
                      label={ title }
                      isDisabled={ false }
                    />
                    { this.renderPaymentField(options) }
                    </div>
                    </div>
                </button>
                { isMobile && isSelected && payment_instructions ? (
                    <div
                      block="CheckoutPayment"
                      elem="paymentInfo"
                    >
                        <h4>Payment Instruction: </h4>
                        <p>{ payment_instructions }</p>
                    </div>
                ) : null }
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
