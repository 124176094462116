/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer
} from 'Component/Product/Product.container';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { DEACTIVATE_ACCOUNT } from 'Component/ShareIconsPopup/ShareIconsPopup.config';
import NotifyStockStatusQuery from 'Query/NotifyStockStatus.query';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import { saveCurrentPage } from 'Store/Config/Config.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { FilterType } from 'Type/Category.type';
import { ChildrenType, MixType } from 'Type/Common.type';
import { LayoutType } from 'Type/Layout.type';
import history from 'Util/History';
import { getSmallImage } from 'Util/Product/Extract';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode, objectToUri } from 'Util/Url';

import {
    processLabel
} from '../../../node_modules/@scandiweb/mageplaza-product-label/src/util/ProductLabels';
import ProductCard from './ProductCard.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Tigerone/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    baseLinkUrl: state.ConfigReducer.base_link_url || '',
    productUsesCategories: state.ConfigReducer.product_use_categories || false,
    categoryUrlSuffix: state.ConfigReducer.category_url_suffix,
    customerEmailId: state.MyAccountReducer?.customer?.email,
    website_id: state.ConfigReducer?.website_id
});

/** @namespace Tigerone/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    saveCurrentPage: (pathname) => dispatch(saveCurrentPage(pathname)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showPopup: (payload) => dispatch(showPopup(DEACTIVATE_ACCOUNT, payload))
    // updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading))
});

/** @namespace Tigerone/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...ProductContainer.propTypes,
        selectedFilters: FilterType,

        // Link building
        productUsesCategories: PropTypes.bool.isRequired,
        categoryUrlSuffix: PropTypes.string.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        defaultSuffix: PropTypes.string,

        hideCompareButton: PropTypes.bool,
        hideWishlistButton: PropTypes.bool,
        isLoading: PropTypes.bool,

        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        showNotification: PropTypes.func.isRequired,
        showPopup: PropTypes.func,
        website_id: PropTypes.string,
        customerEmailId: PropTypes.string,
        shareIconPopup: PropTypes.func,
        // updateLoadStatus: PropTypes.func,

        children: ChildrenType,
        mix: MixType,
        layout: LayoutType
    };

    static defaultProps = {
        ...ProductContainer.defaultProps,
        selectedFilters: {},
        hideWishlistButton: false,
        hideCompareButton: false,
        renderContent: false,
        isLoading: false,
        children: null,
        mix: {},
        layout: GRID_LAYOUT
    };

    containerFunctions = {
        ...this.containerFunctions,
        showSelectOptionsNotification: this.showSelectOptionsNotification.bind(this),
        closeSearchOverlay: this.closeSearchOverlay.bind(this),
        shareIconClicked: this.shareIconClicked.bind(this),
        notifyClick: this.notifyClick.bind(this),
        handleLogin: this.handleLogin.bind(this)
    };

    containerProps() {
        const {
            children,
            mix,
            layout,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            renderContent,
            product,
            imageShow,
            isSearchPageCard = false,
            shareIconPopup,
            selectedProduct,
            categoryUrlSuffix
        } = this.props;

        return {
            ...super.containerProps(),
            children,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            layout,
            mix,
            renderContent,
            imageShow,
            thumbnail: getSmallImage(this.getActiveProduct()) || getSmallImage(product),
            activeProduct: this.getActiveProduct(),
            linkTo: this.getLinkTo(),
            isSearchPageCard,
            shareIconPopup,
            selectedProduct,
            labels: this.getLabels(),
            labelsList: this.getLabelsList(),
            categoryUrlSuffix
        };
    }

    getLabels() {
        const { product, product: { mp_label_data = [] } = {} } = this.props;

        return mp_label_data.map((labelData) => processLabel(
            labelData,
            product,
            true
        ));
    }

    getLabelsList() {
        const { product, product: { mp_label_data = [] } = {} } = this.props;

        return mp_label_data.map((labelData) => processLabel(
            labelData,
            product,
            true
        ));
    }

    closeSearchOverlay() {
        const {
            goToPreviousNavigationState
        } = this.props;

        goToPreviousNavigationState();
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;

        if (!url) {
            return undefined;
        }

        const { parameters } = this.state;
        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category },
            search: objectToUri(parameters)
        };
    }

    showSelectOptionsNotification() {
        const { showNotification } = this.props;

        showNotification('info', 'Please, select product options!');
    }

    handleLogin() {
        const { saveCurrentPage } = this.props;
        const { location: { pathname } } = history;

        saveCurrentPage(pathname);
    }

    notifyClick(data) {
        const {
            customerEmailId, website_id, showNotification
        } = this.props;

        const { id } = this.getActiveProduct();
        const { id: parentId } = data;

        const options = {
            id,
            parentId,
            website_id,
            email: customerEmailId
        };

        const mutation = NotifyStockStatusQuery.getNotifyStockStatus(options);
        return fetchMutation(mutation).then(
            /** @namespace Tigerone/Component/ProductCard/Container/ProductCardContainer/notifyClick/fetchMutation/then */
            (data) => {
                if (!data.error) {
                    const { subscribeStockNotice } = data;
                    if (subscribeStockNotice[0]) {
                        const { message } = subscribeStockNotice[0];
                        if (message === 'true') {
                            showNotification('success', 'Stock status notify Successfully added');
                        } else {
                            showNotification('info', message);
                        }
                    } else {
                        showNotification('success', 'Stock status notify Successfully added');
                    }
                }
            },

            /** @namespace Tigerone/Component/ProductCard/Container/ProductCardContainer/notifyClick/fetchMutation/then/catch */
            (error) => {
                showNotification('error', getErrorMessage(error));
            }
        );
    }

    shareIconClicked(url) {
        const { shareIconPopup } = this.props;

        shareIconPopup(url);
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCard
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
