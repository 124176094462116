/* eslint-disable consistent-return */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import { showNotification } from 'Store/Notification/Notification.action';
import fromCache from 'Util/Cache/Cache';
import history from 'Util/History';
import { getNewParameters, getVariantIndex } from 'Util/Product';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock
} from 'Util/Product/Extract';
import { appendWithStoreCode } from 'Util/Url';
import { validateGroup } from 'Util/Validator';

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

/** @namespace Tigerone/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    saveSelectedConfigDetails: (options) => ProductDispatcher.then(
        ({ default: dispatcher }) => dispatcher.saveSelectedConfigDetails(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
    // TODO extend mapDispatchToProps
});

/** @namespace Tigerone/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    category: state.CategoryReducer?.category
    // TODO extend mapStateToProps
});

/** @namespace Tigerone/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    // TODO implement logic
    containerProps() {
        const {
            quantity,
            parameters,
            adjustedPrice,
            unselectedOptions,
            addToCartTriggeredWithError
        } = this.state;
        const {
            product,
            product: { options = [] } = {},
            configFormRef,
            device,
            isWishlistEnabled,
            category = {},
            saveSelectedConfigDetails
        } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const {
            price_range: priceRange = {},
            dynamic_price: dynamicPrice = false,
            type_id: type
        } = activeProduct || {};

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getMaxQuantity(activeProduct),
            minQuantity: getMinQuantity(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, type, options])
        };

        return {
            isWishlistEnabled,
            unselectedOptions,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            addToCartTriggeredWithError,
            category,
            saveSelectedConfigDetails,
            ...output
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            enteredOptions,
            selectedOptions,
            downloadableLinks
        } = this.state;

        const {
            enteredOptions: prevEnteredOptions,
            selectedOptions: prevSelectedOptions,
            downloadableLinks: prevDownloadableLinks
        } = prevState;

        if (
            enteredOptions !== prevEnteredOptions
            || selectedOptions !== prevSelectedOptions
            || downloadableLinks !== prevDownloadableLinks
        ) {
            this.updateAdjustedPrice();
        }

        const { product } = this.props;
        const { product: prevProduct } = prevProps;

        if (product !== prevProduct) {
            const quantity = ProductContainer.getDefaultQuantity(this.props, this.state);

            if (quantity) {
                this.setQuantity(quantity);
            }

            this.updateSelectedValues();
        }
    }

    async addToCart(isBuyNow = false) {
        this.updateSelectedValues();
        const { showError } = this.props;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();
        await addProductToCart({ products, cartId })
            .catch(
                /** @namespace Tigerone/Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );

        if (isBuyNow) {
            history.push({ pathname: appendWithStoreCode('/checkout') });
        }
    }

    hasError() {
        const { errorMessages, errorFields, values } = validateGroup(this.validator);
        const { showNotification } = this.props;

        if (
            errorFields
            || errorMessages
            || this.validateConfigurableProduct()
            || this.filterAddToCartFileErrors(values)
        ) {
            this.scrollOptionsIntoView();
            this.setState({ addToCartTriggeredWithError: true });
            showNotification('info', 'Incorrect or missing options!');

            return true;
        }

        return false;
    }

    updateConfigurableVariant(key, value, checkEmptyValue = false) {
        const { parameters: prevParameters, quantity } = this.state;
        const { saveSelectedConfigDetails } = this.props;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const { product: { variants, configurable_options } } = this.props;
        const { selectedProduct } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        const newProduct = newIndex === -1 ? null : variants[newIndex];

        saveSelectedConfigDetails({ parameters, quantity });

        if (newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters
            });

            return newProduct;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
