import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'SourceComponent/Field/Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { MixType, RefType } from 'SourceType/Common.type';
import {
    EventsType, FieldAttrType, FieldOptionsType, LabelType, ValidationRuleType
} from 'SourceType/Field.type';

/** @namespace Tigerone/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)),
        attr: FieldAttrType,
        events: EventsType,
        fileEvents: EventsType,
        isDisabled: PropTypes.bool,
        mix: MixType,
        options: FieldOptionsType,
        elemRef: RefType,
        value: PropTypes.number.isRequired,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,

        // Validation
        validationRule: ValidationRuleType,
        validateOn: PropTypes.arrayOf(PropTypes.string),
        showErrorAsLabel: PropTypes.bool,

        // Labels
        label: LabelType,
        subLabel: PropTypes.string,
        addRequiredTag: PropTypes.bool,
        style: PropTypes.bool
    };

    containerProps() {
        const {
            events,
            fileEvents,
            validateOn,
            type,
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            isDisabled,
            mix,
            value,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            style,
            bablicExclude
        } = this.props;
        const { validationResponse, lengthError } = this.state;
        const { validate } = this.containerFunctions;

        // Surrounds events with validation
        const newEvents = { ...events };
        validateOn.forEach((eventName) => {
            const { [eventName]: baseEvent } = events;
            newEvents[eventName] = baseEvent ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            type,
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            value,
            isDisabled,
            bablicExclude,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            validationResponse,
            events: newEvents,
            fileEvents,
            fieldRef: this.fieldRef,
            setRef: this.setRef.bind(this),
            lengthError,
            style
        };
    }
}
export default FieldContainer;
